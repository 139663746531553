<template>
  <div>
    <ReceivedGiftCodesPanel v-if="game" :id-game="game.id"/>
    <NotFoundPanel v-else />
  </div>
</template>

<script>
import ReceivedGiftCodesPanel from "@/components/panels/giftcode/ReceivedGiftCodesPanel";
export default {
  name: "Received",
  components: {ReceivedGiftCodesPanel},
  data() {
    return {
      game: null
    }
  },
  created() {
    this.game = this.$store.getters.currentGame;
  }
}
</script>